:root { 
--gray_50_01:#effffb; 
--gray_50_02:#fff8f7; 
--black_900_7c:#0000007c; 
--red_400:#ec6344; 
--deep_purple_600:#5e519e; 
--gray_800_01:#533e13; 
--green_A100:#c9fcbe; 
--red_100_02:#fbddc7; 
--green_A100_e5:#c9fcbee5; 
--red_100_01:#fddfc8; 
--yellow_800:#f79e1b; 
--deep_orange_900:#853c15; 
--purple_A200:#ff21fb; 
--lime_A700_59:#9ded1a59; 
--yellow_800_01:#d4ab17; 
--pink_100:#ffb2ba; 
--deep_orange_100:#ffbdad; 
--purple_100_a5:#dcbcf9a5; 
--black_900_0a:#0000000a; 
--deep_purple_A200_bf:#755ffdbf; 
--lime_A700_72:#9ded1a72; 
--gray_800:#602c12; 
--deep_orange_600_bf:#f44620bf; 
--black_900_0c:#0000000c; 
--purple_900_59:#470ba759; 
--lime_A700:#9ded1a; 
--deep_orange_900_72:#ba3d0272; 
--cyan_400_01:#26a6d1; 
--deep_orange_600_d8:#f44620d8; 
--purple_100_72:#dcbcf972; 
--purple_A200_e5:#ff21fbe5; 
--teal_A100_bf:#9af4fcbf; 
--blue_100:#bbe6f6; 
--green_A100_59:#c9fcbe59; 
--black_900_19:#00000019; 
--black_900_14:#00000014; 
--indigo_600:#43519e; 
--deep_orange_A200:#ee6f36; 
--pink_A200_99:#f5248499; 
--blue_gray_50:#f1f1f1; 
--deep_orange_600_59:#f4462059; 
--red_300:#c38958; 
--deep_purple_700:#554a90; 
--gray_50:#f4faf9; 
--red_50:#fef2f2; 
--teal_400:#379584; 
--deep_orange_A100_01:#e2a76f; 
--yellow_900:#ed6f25; 
--deep_orange_800:#e13213; 
--pink_800:#bd0043; 
--teal_50_01:#dbf0ec; 
--green_A100_bf:#c9fcbebf; 
--teal_50_02:#d9eeea; 
--pink_400:#e94290; 
--yellow_100:#fff8bc; 
--red_300_02:#ee777b; 
--lime_A700_99:#9ded1a99; 
--lime_600:#dbba44; 
--amber_400:#f9c81b; 
--purple_900_72:#470ba772; 
--gray_900:#252525; 
--red_300_01:#ed7279; 
--yellow_800_d8:#fc9e1bd8; 
--teal_50:#c9f4ec; 
--teal_A200:#56eff4; 
--gray_100:#fdf2f6; 
--orange_100:#f1d0a4; 
--yellow_900_02:#ed6d17; 
--black_900_75:#00000075; 
--yellow_900_01:#f28b1a; 
--orange_50_01:#fffbdf; 
--orange_50_02:#fff2e8; 
--indigo_700:#363f92; 
--light_blue_300_d8:#4bc8eed8; 
--red_A700_63:#d1000063; 
--deep_orange_A100:#e2a66f; 
--pink_100_01:#f9a8c6; 
--teal_400_63:#37958463; 
--light_blue_A100:#7cdbff; 
--deep_purple_700_01:#60388d; 
--light_blue_500:#00b9ed; 
--green_A100_a5:#c9fcbea5; 
--deep_orange_600_72:#f4462072; 
--teal_300:#5abba3; 
--deep_orange_800_01:#ca4819; 
--deep_orange_700:#e9521d; 
--deep_orange_600_a5:#f44620a5; 
--light_blue_300_e5:#4bc8eee5; 
--red_A700:#d10000; 
--deep_orange_900_bf:#ba3d02bf; 
--red_50_02:#fff2e9; 
--yellow_100_01:#fef7bb; 
--red_50_01:#ffeced; 
--amber_300:#f9d450; 
--gray_200:#f0f0f0; 
--indigo_400:#685ba4; 
--deep_orange_900_d8:#ba3d02d8; 
--gray_100_01:#f7f7f7; 
--yellow_800_59:#fc9e1b59; 
--green_600_00:#27ae6000; 
--light_blue_500_e5:#02a3f4e5; 
--red_A700_02:#e60023; 
--white_A700_28:#ffffff28; 
--red_A700_01:#eb001b; 
--white_A700:#ffffff; 
--purple_100_d8:#dcbcf9d8; 
--cyan_400:#3bb8e0; 
--green_A100_99:#c9fcbe99; 
--blue_gray_900_19:#1d2c3319; 
--red_900:#b3261e; 
--pink_A200_59:#f5248459; 
--deep_purple_500:#6750a4; 
--red_500:#ed4731; 
--purple_100_bf:#dcbcf9bf; 
--red_100:#ffd9dc; 
--lime_A700_e5:#9ded1ae5; 
--black_900:#000000; 
--teal_A100_e5:#9af4fce5; 
--deep_orange_600:#f44620; 
--light_blue_500_d8:#02a3f4d8; 
--green_A400_00:#3ae18000; 
--gray_900_02:#1c1b1f; 
--gray_700:#666666; 
--gray_900_03:#1f1f1f; 
--gray_900_04:#1d1b20; 
--orange_A700:#ff5f00; 
--amber_600:#fab400; 
--gray_900_01:#400011; 
--light_blue_50:#e6f8ff; 
--amber_200:#ffdb7f; 
--gray_300:#e3e3e3; 
--orange_300:#feba4b; 
--gray_300_01:#e6e5e5; 
--orange_50:#ffe7d4; 
--white_A700_01:#fffbfe; 
--blue_100_01:#bee9ff; 
--indigo_900:#2b3579; 
--blue_100_02:#bae5f4; 
--light_blue_50_01:#e0f4ff;
--blue_100_03:#DCF1FD;
---blue_100_04:#E6F8FF;
---gray_600:#6A6A71;
}